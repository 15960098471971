import {
  Box,
  Image,
  Button,
  Text,
  Checkbox,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormErrorMessage,
  useMediaQuery,
  extendTheme,
  ChakraProvider,
  FormLabel,
  Link
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import bannerStyle from "./Banner.module.css";
import Login from "../Login/Login";
import {
  isValidDomain,
  isValidEmail,
} from "../../../GlobalActions/companyEmailValidation";
import OtpModal from "../Otp/Otp";
import { ShowAlert } from "../../../GlobalComponents/ShowAlert/ShowAlert";
import { LoginOtp, sendOtp } from "../Login/Action/loginAction";
import { getCompanyAction } from "./Action/getCompanyAction";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PhoneInput from "react-phone-input-2";
import classNames from "classnames";
import loginStyle from "../Login/Login.module.css";
import { getDocument } from "../../../http";
interface BannerParam {
  params: string;
}
const customTheme = extendTheme({
  colors: {
    custom: {
      500: "#00A7B5", // Adjust the shade if needed
    },
  },
});
const Banner: React.FC<BannerParam> = ({ params }) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); //eslint-disable-line
  const { isOpen: messageModalIsOpen, onOpen: messageModalOnOpen, onClose: messageModalOnClose } = useDisclosure();
  const [company, setCompany] = useState<any[]>([])
  const [otpModals, showOtpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loginModal, showLoginModal] = useState(false);
  //eslint-disable-next-line
  const [dynamicWords, setDynamicWords] = useState(["time", "where"]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0); 
  //eslint-disable-next-line
  const [desktopWidth, setDesktopWidth] = useState(window.innerWidth);
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  const [isLargerThan540] = useMediaQuery("(min-width: 540px)");
  const phoneInputClass = classNames(loginStyle.myPhoneInputClass);
  const containerClass = classNames(loginStyle.myContainerClass);
  const [validNumber, setValidNumber] = useState<boolean>(false);
  const [disable, setDisable] = useState(false);
  const [disablePhNum, setDisablePhNum] = useState(false);
  const [number, setNumber] = useState<LoginOtp>({
    countryCode: "",
    number: "",
  });
  const [image,setImage]=useState("")
  const navigate = useNavigate()
  const closeModal = () => {
    showLoginModal(false);
  };
  const handleOtpModal = () => {
    showOtpModal(true);
  };


  const handleCatchInput = (e: any) => {
    const newEmail = e.target.value;
    let ce=isValidEmail(newEmail.trim());
    let ff= isValidDomain(newEmail.trim(), company[0].emailPattern)

    console.log('ce:', ce,ff,!validNumber);
    if(isValidEmail(newEmail.trim()) && isValidDomain(newEmail.trim(), company[0].emailPattern) && !validNumber ){
      setDisablePhNum(true)
    }else{
      setDisablePhNum(false)
    }
    setEmail(newEmail.trim());
  };
  
  const handlePhoneChange = (value: string) => {
    setNumber({ countryCode: "91", number: value });
    value.length === 10 ? setValidNumber(true) : setValidNumber(false);
    console.log('value:', value.length);
    if ((value.length === 10 ) && (!isValidEmail(email) || !isValidDomain(email, company[0].emailPattern)))
      setDisable(true);
    else if (value.length !== 10) {
      setDisable(false);
    }
  };
  const handleGetOtp = async () => {
    setIsLoading(true);
    if (email !== "" || validNumber) {     
      if ((!isValidEmail(email) || !isValidDomain(email, company[0].emailPattern)) &&!validNumber) {
        ShowAlert("warning", `Enter valid Email Or Mobile`);
        setIsLoading(false);
        return;
      }
      try {
        await sendOtp({ ...number, email:email });
        setIsLoading(false);
        handleOtpModal();
      } catch (error: any) {
        setIsLoading(false);
        if (error.response?.data?.statusCode?.toString() === "404") {
          messageModalOnOpen();
        } else {
          ShowAlert("warning", `Something went wrong, Please try again later`);
        }
      }
    }else{
      ShowAlert("warning", `Enter valid Email or Mobile`);
      setIsLoading(false);
    }
  };
  const updateDynamicWord = () => {
    setCurrentWordIndex((prevIndex) => (prevIndex + 1) % dynamicWords.length);
  };

  const fetchData = async () => {
    try {
      if (params) {

        const data = await getCompanyAction(params);
      
        setCompany(data.data)
        let regex = /apis\.pococare\.com/; 
        
        if (data?.data[0]?.logo&&regex.test(data?.data[0]?.logo)) {
      
          await fetchPhoto(data?.data[0]?.logo)
        }else if(data?.data[0]?.logo){
          setImage(data?.data[0]?.logo)
        }
      }

    } catch (error: any) {
      console.log(error)
      if (error.response.status === 404) {
        navigate('/companyNotFound')
      }
    }
  };

  
  async function fetchPhoto(url:string) {
    try {
      const response = await getDocument(url);
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const finalUrl = URL.createObjectURL(blob);
      setImage(finalUrl);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchData()
    const updateDesktopWidth = () => {
      setDesktopWidth(window.innerWidth);
    };
    updateDesktopWidth();
    window.addEventListener("resize", updateDesktopWidth);
    const interval = setInterval(updateDynamicWord, 3000); // Change the word every 3 seconds
    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", updateDesktopWidth);
    };
    //eslint-disable-next-line
  }, []); // Include any dependencies in the dependency array

  return (
    <>
      <ChakraProvider theme={customTheme}>
        <Box className={bannerStyle.container}>
          <Box className={bannerStyle.wrapper}>
            <Box className={bannerStyle.banner_Section}>
              {!params &&
                <Box
                  // position="relative"
                  // border={"1px solid red"}
                  className={bannerStyle.banner_Heading}
                >
                  <Box w={"100%"} className={bannerStyle.banner_image}>
                    {isLargerThan1000 ? (
                      <Box
                        // display="flex"
                        alignItems={"center"}
                        className={bannerStyle.login_Section}
                      >
                        <Box>
                          <Text className={bannerStyle.banner_Text}>
                            Medical Emergency
                          </Text>
                          <Text className={bannerStyle.banner_Text}>
                            "Incident to Hospital Admission"
                          </Text>
                          <Text className={bannerStyle.banner_Text}>
                            Service Any
                            <span
                              className={
                                dynamicWords[currentWordIndex] === "time"
                                  ? bannerStyle.dynamic_Pink
                                  : bannerStyle.dynamic_Green
                              }
                            >
                              {dynamicWords[currentWordIndex]}
                            </span>
                          </Text>
                          <Text className={bannerStyle.banner_Sub_Text}>
                            “Peace of Mind for you and your family”
                          </Text>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Box className={bannerStyle.banner_left_images}>
                      <Box>
                        <Box className={bannerStyle.main_image}>
                          <Image borderRadius={"50%"} src="Images/banner_img_1.jpg" />
                          <LazyLoadImage
                            className={bannerStyle.circle_image1}
                            src={`Images/banner_circle_1.jpg`}
                          />

                          <LazyLoadImage
                            className={bannerStyle.circle_image2}
                            src={`Images/ambulance.jpg`}
                          />
                          <LazyLoadImage
                            className={bannerStyle.circle_image3}
                            src={`Images/banner_circle_5.jpg`}
                          />
                          <LazyLoadImage
                            className={bannerStyle.circle_image4}
                            src={`Images/banner_circle_3.jpg`}
                          />
                          <LazyLoadImage
                            className={bannerStyle.circle_image5}
                            src={`Images/banner_circle_2.jpg`}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {!isLargerThan1000 && isLargerThan540 && (
                      <Box
                        display="flex"
                        alignItems={"center"}
                        className={bannerStyle.login_Section}
                      >
                        <Box>
                          <Text className={bannerStyle.banner_Text}>
                            Medical Emergency "Incident to Hospital Admission"
                          </Text>

                          <Text className={bannerStyle.banner_Text}></Text>
                          <Text className={bannerStyle.banner_Text}>
                            Service Any
                            <span
                              className={
                                dynamicWords[currentWordIndex] === "time"
                                  ? bannerStyle.dynamic_Pink
                                  : bannerStyle.dynamic_Green
                              }
                            >
                              {dynamicWords[currentWordIndex]}
                            </span>
                          </Text>
                          <Text className={bannerStyle.banner_Sub_Text}>
                            “Peace of Mind for you and your family”
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {!isLargerThan540 ? (
                      <Box
                        display="flex"
                        alignItems={"center"}
                        textAlign={"center"}
                        className={bannerStyle.login_Section}
                      >
                        <Box>
                          <Text className={bannerStyle.banner_Text}>
                            Medical Emergency
                          </Text>
                          <Text className={bannerStyle.banner_Text}>
                            "Incident to Hospital Admission"
                          </Text>
                          <Text className={bannerStyle.banner_Text}>
                            Service Any
                            <span
                              className={
                                dynamicWords[currentWordIndex] === "time"
                                  ? bannerStyle.dynamic_Pink
                                  : bannerStyle.dynamic_Green
                              }
                            >
                              {dynamicWords[currentWordIndex]}
                            </span>
                          </Text>
                          <Text className={bannerStyle.banner_Sub_Text}>
                            “Peace of Mind for you and your family”
                          </Text>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              }
              {
                params && company.length &&
                <Box
                  className={bannerStyle.banner_Heading}
                >
                  <Box className={bannerStyle.banner_image}>
                    {isLargerThan1000 ? (
                      <Box className={bannerStyle.login_Section}>
                        <Box>
                          <Text className={bannerStyle.banner_Text}>
                            Medical Emergency
                          </Text>
                          <Text className={bannerStyle.banner_Text}>
                            "Incident to Hospital Admission"
                          </Text>

                          <Text className={bannerStyle.banner_Text}>
                            Service Any
                            <span
                              className={
                                dynamicWords[currentWordIndex] === "time"
                                  ? bannerStyle.dynamic_Pink
                                  : bannerStyle.dynamic_Green
                              }
                            >
                              {dynamicWords[currentWordIndex]}
                            </span>
                          </Text>
                          <Text
                            mb={".5rem"}
                            className={bannerStyle.banner_Sub_Text}
                          >
                            “Peace of Mind for you and your family”
                          </Text>
                          <Box >
                            <Box>
                              <Box className={bannerStyle.logoFlex}>
                                <Box className={bannerStyle.client_logo_style}>
                                  <Image
                                    // w={"100%"}
                                    // border={"2px solid  red"}
                                    // style={{ display: "block", objectFit: "cover" }}
                                    src={`${image}`}

                                  />
                                </Box>
                                <Box className={bannerStyle.poco_logo_style}>
                                  <Image w={"100%"} src="/Images/logo.png" />
                                </Box>
                              </Box>
                              <Box className={bannerStyle.login_grp}>
                              <FormControl>
                                  <FormLabel htmlFor="email">Mobile</FormLabel>
                                  <PhoneInput
                                    country="in"
                                    onlyCountries={["in"]}
                                    autoFormat={true}
                                    disableCountryCode={true}
                                    placeholder="Phone Number"
                                    inputClass={phoneInputClass}
                                    containerClass={containerClass}
                                    onChange={handlePhoneChange}
                                    disabled={disablePhNum}
                                  />
                                </FormControl>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <Text>OR</Text>
                                </Box>
                                <FormControl
                                >
                                  <Box className={bannerStyle.inputWrapper}>
                                    <input
                                    disabled={disable}
                                      className={bannerStyle.input_style}
                                      value={email}
                                      onChange={handleCatchInput}
                                      placeholder={`xyz@${params}.com`}
                                    />
                                  </Box>
                                  <FormErrorMessage>
                                    Enter a valid E-mail with the correct
                                    domain.
                                  </FormErrorMessage>
                                </FormControl>
                              </Box>
                              <Box className={bannerStyle.TermsAndCondition}>
                                <Box className={bannerStyle.checkbox}>
                                  <Checkbox
                                    // border={"1px"}
                                    borderColor={"00A7B5"}
                                    borderRadius={"3px"}
                                    isFocusable={true}
                                    colorScheme="custom"
                                    style={{ color: "#00A7B5" }}
                                    defaultChecked={isChecked}
                                    onChange={(e) => {
                                      setIsChecked(!isChecked);
                                      setIsButtonDisabled(!isButtonDisabled);
                                    }}
                                  ></Checkbox>
                                </Box>
                                <Box>
                                  <label
                                    htmlFor="terms-checkbox"
                                    className={bannerStyle.text_style}
                                  >
                                    <span>
                                      By activating the account, I agree with
                                      the{" "}
                                    </span>
                                    <Link
                                    style={{
                                      color: "#008470",
                                      cursor: "pointer",
                                    }}
                                        href="/terms&condition"
                                        target="_blank"
                                      >
                                        "Terms of  Usage"
                                      </Link> {" "}
                                  </label>
                                  and{" "}
                                  <label htmlFor="terms-checkbox">
                                  <Link
                                   style={{
                                    color: "#008470",
                                    cursor: "pointer",
                                  }}
                                        href="/privacyPolicy"
                                        target="_blank"
                                      >
                                        "Privacy Policy"
                                      </Link>
                                  </label>
                                </Box>
                              </Box>
                              <Box >
                                <Button
                                  loadingText="Loading"
                                  isLoading={isLoading}
                                  onClick={handleGetOtp}
                                  isDisabled={isButtonDisabled}
                                  // onClick={}
                                  marginTop={"1rem"}
                                  bg="var(--teal, #00A7B5)"
                                  _hover={{ bg: "var(--teal, #00A7B5)" }}
                                  colorScheme="teal"
                                >
                                  <span className={bannerStyle.buttonText}>
                                    Get OTP
                                  </span>
                                </Button>
                              </Box>
                              <Box>
                                {params === 'persistent' ? <Text className={bannerStyle.alert_Text}>
                                  Currently, this service benefit is available
                                  only for Persistent Nagpur Employees as a
                                  pilot initiative.
                                </Text> : <></>}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Box className={bannerStyle.banner_left_images}>
                      <Box>
                        <Box className={bannerStyle.main_image}>
                          <Image borderRadius={"50%"} src="Images/banner_img_1.jpg" />

                          <Image
                            className={bannerStyle.circle_image1}
                            src={`Images/banner_circle_1.jpg`}
                          />

                          <Image
                            className={bannerStyle.circle_image2}
                            src={`Images/banner_circle_2.jpg`}
                          />
                          <Image
                            className={bannerStyle.circle_image3}
                            src={`Images/banner_circle_3.jpg`}
                          />
                          <Image
                            className={bannerStyle.circle_image4}
                            src={`Images/banner_circle_5.jpg`}
                          />
                          <Image
                            className={bannerStyle.circle_image5}
                            src={`Images/banner_circle_5.jpg`}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {!isLargerThan1000 && isLargerThan540 && (
                      <Box
                        display="flex"
                        alignItems={"center"}
                        className={bannerStyle.login_Section}
                      >
                        <Box>
                          <Text className={bannerStyle.banner_Text}>
                            End to End "Incident to Hospital Admission"
                          </Text>

                          <Text className={bannerStyle.banner_Text}></Text>
                          <Text className={bannerStyle.banner_Text}>
                            Medical Emergency Assistance Any
                            <span
                              className={
                                dynamicWords[currentWordIndex] === "time"
                                  ? bannerStyle.dynamic_Pink
                                  : bannerStyle.dynamic_Green
                              }
                            >
                              {dynamicWords[currentWordIndex]}
                            </span>
                          </Text>
                          <Text className={bannerStyle.banner_Sub_Text}>
                            For you and your family members.
                          </Text>
                          <Box >
                            <Box>
                              <Box className={bannerStyle.logoFlex}>
                                <Box className={bannerStyle.client_logo_style}>
                                  <Image
                                    w={"100%"}
                                    src={`${image}`}
                                  />
                                </Box>
                                <Box className={bannerStyle.poco_logo_style}>
                                  <Image w={"100%"} src="/Images/logo.png" />
                                </Box>
                              </Box>
                              <Box className={bannerStyle.login_grp}>
                              <FormControl>
                                  <FormLabel htmlFor="email">Mobile</FormLabel>
                                  <PhoneInput
                                    country="in"
                                    onlyCountries={["in"]}
                                    autoFormat={true}
                                    disableCountryCode={true}
                                    placeholder="Phone Number"
                                    inputClass={phoneInputClass}
                                    containerClass={containerClass}
                                    onChange={handlePhoneChange}
                                    disabled={disablePhNum}
                                  />
                                </FormControl>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <Text>OR</Text>
                                </Box>
                                <FormControl
                                >
                                  <Box className={bannerStyle.inputWrapper}>
                                    <input
                                    disabled={disable}
                                      className={bannerStyle.input_style}
                                      value={email}
                                      onChange={handleCatchInput}
                                      placeholder={`xyz@${params}.com`}
                                    />
                                  </Box>
                                  <FormErrorMessage>
                                    Enter a valid E-mail with the correct
                                    domain.
                                  </FormErrorMessage>
                                </FormControl>
                              </Box>
                              
                              <Box className={bannerStyle.TermsAndCondition}>
                                <Box className={bannerStyle.checkbox}>
                                  <Checkbox
                                    // border={"1px"}
                                    borderColor={"00A7B5"}
                                    borderRadius={"3px"}
                                    isFocusable={true}
                                    colorScheme="custom"
                                    style={{ color: "#00A7B5" }}
                                    defaultChecked={isChecked}
                                    onChange={(e) => {
                                      setIsChecked(!isChecked);
                                      setIsButtonDisabled(!isButtonDisabled);
                                    }}
                                  ></Checkbox>
                                </Box>
                                <Box>
                                  <label
                                    htmlFor="terms-checkbox"
                                    className={bannerStyle.text_style}
                                  >
                                    <span>
                                      By activating the account, I agree with
                                      the{" "}
                                    </span>
                                    <Link
                                    style={{
                                      color: "#008470",
                                      cursor: "pointer",
                                    }}
                                        href="/terms&condition"
                                        target="_blank"
                                      >
                                        "Terms of  Usage"
                                      </Link> {" "}
                                  </label>
                                  and{" "}
                                  <label htmlFor="terms-checkbox">
                                  <Link
                                   style={{
                                    color: "#008470",
                                    cursor: "pointer",
                                  }}
                                        href="/privacyPolicy"
                                        target="_blank"
                                      >
                                        "Privacy Policy"
                                      </Link>
                                  </label>
                                </Box>
                              </Box>
                              <Box>
                                <Button
                                  loadingText="Loading"
                                  isLoading={isLoading}
                                  onClick={handleGetOtp}
                                  isDisabled={isButtonDisabled}
                                  // onClick={}
                                  marginTop={"1rem"}
                                  bg="var(--teal, #00A7B5)"
                                  _hover={{ bg: "var(--teal, #00A7B5)" }}
                                  colorScheme="teal"
                                >
                                  <span className={bannerStyle.buttonText}>
                                    Get OTP
                                  </span>
                                </Button>
                              </Box>
                              <Box>
                                {params === 'persistent' ? <Text className={bannerStyle.alert_Text}>
                                  Currently, this service benefit is available
                                  only for Persistent Nagpur Employees as a
                                  pilot initiative.
                                </Text> : <></>}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {!isLargerThan540 ? (
                      <Box
                        display="flex"
                        alignItems={"center"}
                        textAlign={"center"}
                        className={bannerStyle.login_Section}
                      >
                        <Box>
                          <Text className={bannerStyle.banner_Text}>
                            End to End
                          </Text>
                          <Text className={bannerStyle.banner_Text}>
                            "Incident to Hospital Admission"
                          </Text>
                          <Text className={bannerStyle.banner_Text}>
                            Medical Emergency
                          </Text>
                          <Text className={bannerStyle.banner_Text}>
                            Assistance Any
                            <span
                              className={
                                dynamicWords[currentWordIndex] === "time"
                                  ? bannerStyle.dynamic_Pink
                                  : bannerStyle.dynamic_Green
                              }
                            >
                              {dynamicWords[currentWordIndex]}
                            </span>
                          </Text>
                          <Text className={bannerStyle.banner_Sub_Text}>
                            For you and your family members.
                          </Text>
                          {/* ////login */}
                          <Box>
                            <Box>
                              <Box className={bannerStyle.logoFlex}>
                                <Box className={bannerStyle.client_logo_style}>
                                  <Image
                                    w={"100%"}
                                    src={`${image}`}
                                  />
                                </Box>
                                <Box className={bannerStyle.poco_logo_style}>
                                  <Image w={"100%"} src="/Images/logo.png" />
                                </Box>
                              </Box>
                              <Box className={bannerStyle.login_grp}>
                              <FormControl>
                                  <FormLabel htmlFor="email">Mobile</FormLabel>
                                  <PhoneInput
                                    country="in"
                                    onlyCountries={["in"]}
                                    autoFormat={true}
                                    disableCountryCode={true}
                                    placeholder="Phone Number"
                                    inputClass={phoneInputClass}
                                    containerClass={containerClass}
                                    onChange={handlePhoneChange}
                                    disabled={disablePhNum}
                                  />
                                </FormControl>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <Text>OR</Text>
                                </Box>
                                <FormControl
                                >
                                  <Box className={bannerStyle.inputWrapper}>
                                    <input
                                    disabled={disable}
                                      className={bannerStyle.input_style}
                                      value={email}
                                      onChange={handleCatchInput}
                                      placeholder={`xyz@${params}.com`}
                                    />
                                  </Box>
                                  <FormErrorMessage>
                                    Enter a valid E-mail with the correct
                                    domain.
                                  </FormErrorMessage>
                                </FormControl>
                              </Box>
                              <Box className={bannerStyle.TermsAndCondition}>
                                <Box className={bannerStyle.checkbox}>
                                  <Checkbox
                                    // border={"1px"}
                                    borderColor={"00A7B5"}
                                    borderRadius={"3px"}
                                    isFocusable={true}
                                    colorScheme="custom"
                                    style={{ color: "#00A7B5" }}
                                    defaultChecked={isChecked}
                                    onChange={(e) => {
                                      setIsChecked(!isChecked);
                                      setIsButtonDisabled(!isButtonDisabled);
                                    }}
                                  ></Checkbox>
                                </Box>
                                <Box>
                                  <label
                                    htmlFor="terms-checkbox"
                                    className={bannerStyle.text_style}
                                  >
                                    <span>
                                      By activating the account, I agree with
                                      the{" "}
                                    </span>
                                    <Link
                                    style={{
                                      color: "#008470",
                                      cursor: "pointer",
                                    }}
                                        href="/terms&condition"
                                        target="_blank"
                                      >
                                        "Terms of  Usage"
                                      </Link> {" "}
                                  </label>
                                  and{" "}
                                  <label htmlFor="terms-checkbox">
                                  <Link
                                   style={{
                                    color: "#008470",
                                    cursor: "pointer",
                                  }}
                                        href="/privacyPolicy"
                                        target="_blank"
                                      >
                                        "Privacy Policy"
                                      </Link>
                                  </label>
                                </Box>
                              </Box>
                              <Box>
                                <Button
                                  loadingText="Loading"
                                  isLoading={isLoading}
                                  onClick={handleGetOtp}
                                  isDisabled={isButtonDisabled}
                                  // onClick={}
                                  marginTop={"1rem"}
                                  bg="var(--teal, #00A7B5)"
                                  _hover={{ bg: "var(--teal, #00A7B5)" }}
                                  colorScheme="teal"
                                >
                                  <span className={bannerStyle.buttonText}>
                                    Get OTP
                                  </span>
                                </Button>
                              </Box>
                              <Box>
                                {params === 'persistent' ? <Text className={bannerStyle.alert_Text}>
                                  Currently, this service benefit is available
                                  only for Persistent Nagpur Employees as a
                                  pilot initiative.
                                </Text> : <></>}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>

              }

            </Box>
          </Box>
        </Box>
        {/* <StepsComponent/> */}

        <Login loginModal={loginModal} onClose={closeModal} />

       

        {otpModals && (
          <OtpModal
            email={email}
            showOtpModal={otpModals}
            setShowOtpModal={showOtpModal}
            onClose={() => {
              // Close the OTP modal and set showOtpModal to false
              showOtpModal(false);
            }}
          />
        )}

        {/* warn modal */}
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody fontWeight={"1000"}>
              <Text>Hello,</Text>
              <Text textAlign={"justify"}>
                We could not find your information in our system. So, we are
                unable to proceed with the activation of your account. Please
                contact your HR team to initiate the account activation process.
              </Text>
              <br />
              <Text>Thank you.</Text>
              <Text>Pococare Heartbeat Team!</Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>

      {/* message modal */}

      <Modal isOpen={messageModalIsOpen} onClose={messageModalOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Couldn't find pococare account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Hello,<br />
            We couldn't find your information in our system, so we are unable to proceed with the activation of your account. Please contact your HR team to initiate the account activation process.<br />
            <br />Thank you.<br />
            Team Pococare Heart Beat!
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={messageModalOnClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Banner;
