import './App.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer } from "react-toastify";
//import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';
//for leaflet css imported directly instead of using cdn//

import MainRoute from './Routes/MainRoute';
import { useEffect, useState } from 'react';
import { ScrollSection } from './GlobalComponents/Footer/scroll/ScrollSection';
import ReactGA from 'react-ga';
import 'leaflet/dist/leaflet.css';
//for leaflet css imported directly instead of using cdn//
const queryClient = new QueryClient()

function App() {
  //eslint-disable-next-line
  const [installPrompt, setInstallPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setInstallPrompt(e);
    };


    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // const handleInstallClick = () => {

  //   if (installPrompt) {
  //     // @ts-ignore
  //     installPrompt.prompt();
  //     // @ts-ignore
  //     installPrompt.userChoice.then((choiceResult:any) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the install prompt');
  //       } else {
  //         console.log('User declined the install prompt');
  //       }
  //       setInstallPrompt(null);
  //     });
  //   }
  // };


  // Initialize React Ga with your tracking ID
  ReactGA.initialize('G-LS07GMGQY6');

  return (
    <>
      {/* {installPrompt && (
        <button onClick={handleInstallClick}>
          Install the app
        </button>
      )} */}
      <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ScrollSection>
        <ToastContainer autoClose={3000} />
        <MainRoute />
      </ScrollSection>
      </QueryClientProvider>
    </>
  );
}

export default App;
