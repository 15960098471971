import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import TxtComponent from "./Components/B2CRegistrationComponents/TxtComponent";
import RegistrationProcess from "./Components/B2CRegistrationComponents/RegistrationProcess";
import Navbar from "../../../GlobalComponents/Navbar/Navbar";

const B2CRegistrationForm = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isTab] = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Navbar />
    <Box
      bgGradient="linear(180deg, #FFF 0%, #D2F0F2 100%)"
      position={"fixed"}
      marginTop={isMobile? "110px":"120px"}
      minH={"full"}
      top={0}
      width={"full"}
    >
      <Flex
        flexDir={isMobile ? "column" : isTab ? "column" : "row"}
        backgroundImage="url('/Images/image_prev_ui.svg')"
        width={"72%"}
        margin={"auto"}
        height={"auto"}
        justifyContent={isMobile ? "start" : "space-between"}
        alignItems={"center"}
      >
       {/* Adjusting the alignment of TxtComponent */}
       <Box
            width={isMobile || isTab ? "full" : "auto"} 
            textAlign={isMobile || isTab ? "left" : "center"} 
            mb={isMobile ? 4 : "56px"} 
          >
            <TxtComponent />
          </Box>

          {/* Keep RegistrationProcess centered as required */}
          <Box
            width={isMobile ? "full" : "auto"}
            display="flex"
            justifyContent={isMobile ? "center" : "flex-end"}
          >
            <RegistrationProcess />
          </Box>
      </Flex>
      </Box>
    </>
  );
};

export default B2CRegistrationForm;
