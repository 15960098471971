import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import profileStyle from "./ProfileBasicDetails.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { calculateAge } from "../Action/calculateAge";
import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import ProfileProgress from "./ProfileProgress";
import QRCode from "qrcode.react";
import apiConfig from "../../../apiConfig/apiConfig";
import { BenDataProps } from "../../../Helper/BenInterface";
import { FaHome } from "react-icons/fa";
import { getDocument } from "../../../http";
const frontEndURL = apiConfig.api.frontEndURL;

const ProfileBasicDetail: React.FC<{
  data: BenDataProps;
  downloadProfilePdf: () => void;
  isLoading: Boolean;
}> = ({ data, downloadProfilePdf, isLoading }) => {
  // const subscriber = useSelector((state: any) => state.subscriber);
  const userData: any = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const [image,setImage]=useState("")

  const [completionPercentage, setCompletionPercentage] = useState(0);
  const handleEdit = (benId: string) => {
    navigate(`/addBenFullDetails/${benId}`);
    sessionStorage.setItem("stepNumber", "1"); //here .ts through error if i send as number
  };

  useEffect(() => {
    const check = data?.progress || 0;
    const totalPercentage = Math.round((check / 6) * 100);
    setCompletionPercentage(totalPercentage);


     const fetchphoto=async()=>{
          try{
            const response = await getDocument(data?.personalInfo?.image);
           const blob = new Blob([response.data], { type: response.headers["content-type"] });
           const  url = URL.createObjectURL(blob);
         setImage(url)
          }catch(err){
            console.log(err)
          }
    
         }
         if(data?.personalInfo?.image){
          fetchphoto()
         }
         
  }, [completionPercentage, data]);

  return (
    <Box className={profileStyle.ben_PersonalDetails}>
      <Box className={profileStyle.personalDetails_Top}>
        <Center>
          <Box
            mt={"1rem"}
            padding={".2rem"}
            borderRadius={"1rem"}
            backgroundColor={"white"}
            onClick={() => navigate("/dashboard")}
          >
            <Text color={"#008470"} fontSize={"2rem"}>
              <FaHome />
            </Text>
          </Box>
        </Center>
        <Box>
          <Heading textAlign={"center"} size="md" mt={"4"} mb={"6"}>
            Your Pococare Emergency Ready Kit (PERK)
          </Heading>
        </Box>
        <Box className={profileStyle.personDetails_Child}>
          <Box className={profileStyle.personDetails_left}>
            <Box className={profileStyle.personDetails_right_img}>
              {data?.personalInfo?.image ? (
                data?.personalInfo?.image.split(":").length !== 2 ? (
                  <Image
                    width={"100%"}
                    src={`${apiConfig.api1.baseURL}/beneficiariesProfilePhotos/${data?.personalInfo?.image}`}
                    alt="Invalid"
                  />
                ) : (
                  <Image
                    width={"100%"}
                    src={image}
                    alt="Invalid"
                    crossOrigin="anonymous"
                  />
                )
              ) : (
                <Text className={profileStyle.benName_center}>
                  {(data.personalInfo?.fullName?.[0] || "").toUpperCase()}
                </Text>
              )}

              <Box className={profileStyle.circleProgress}>
                <ProfileProgress
                  percentage={completionPercentage}
                  strokeWidth={3}
                  circleSize={"115px"}
                />
              </Box>
            </Box>
            <Box className={profileStyle.nameContainer}>
              <Text className={profileStyle.benName} fontSize={"22px"}>
                {data.personalInfo.fullName || ""}
              </Text>
              <Text className={profileStyle.relationToBen} fontSize={"18px"}>
                {data?.personalInfo?.relationshipToSubscriber}
              </Text>
            </Box>
          </Box>
          <Box className={profileStyle.personDetails_right}>
            <Box className={profileStyle.personalDetails_RightDet}>
              <Box textAlign={"center"}>
                <Flex justifyContent={"space-around"}>
                  <QRCode
                    value={`${frontEndURL || "https://www.pococare.com"
                      }/trigger-emergency/${data?._id}`}
                    id="canvas"
                    size={120}
                    level={"H"}
                    includeMargin={true}
                    imageSettings={{
                      src: "/Images/transparentLogo.png",
                      x: 30,
                      y: 30,
                      height: 30,
                      width: 30,
                      excavate: true,
                    }}
                  />
                </Flex>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  Scan me during an emergency
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    color: "blue",
                    fontWeight: "bold",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="https://pocostorage.blob.core.windows.net/others/Pococare_QR_Code_Manual.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Click here for the QR code manual
                  </a>
                </p>
                <Button
                  loadingText="Downloading"
                  isLoading={isLoading as boolean}
                  onClick={() => {
                    downloadProfilePdf();
                  }}
                >
                  Click here to download profile
                </Button>
              </Box>
            </Box>
          </Box>
          {/* //child */}
          <Box className={profileStyle.topColor}></Box>
        </Box>
        {/* <Box className={profileStyle.personalDetails_ChildMobile}>
                    <Box>
                        hhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
                        <Flex justifyContent={"space-around"}>
                            <QRCode
                                value={`${frontEndURL}/trigger-emergency/${data?._id}`}
                                id="canvas"
                                size={120}
                                level={"H"}
                                includeMargin={true}
                                imageSettings={{
                                    // title: "avc",
                                    src: "/Images/transparentLogo.png",
                                    x: 30,
                                    y: 30,
                                    height: 30,
                                    width: 30,
                                    excavate: true,
                                }}
                            />
                        </Flex>
                        <p style={{ fontWeight: "bold", textAlign: "center" }}>
                            Scan me during an emergency
                        </p>
                        <p
                            style={{
                                fontSize: "15px",
                                color: "blue",
                                fontWeight: "bold",
                                marginTop: "10px",
                                cursor: "pointer",
                                textAlign: "center"
                            }}
                        >
                            <a
                                href="https://csg1003200234760c51.blob.core.windows.net/poco-prod/Pococare_QR_Code_Manual.pdf"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Click here for the QR code manual
                            </a>
                        </p>
                    </Box>
                    <Box className={profileStyle.nameContainer}>
                        <Text className={profileStyle.benName} fontSize={"18px"} textAlign={"center"}>{data.personalInfo.fullName} ({data.personalInfo.relationshipToSubscriber}) {completionPercentage} %</Text>
                    </Box>
                </Box> */}
        <Divider borderWidth={"2px"} marginTop={"15px"} />
      </Box>
      <Box className={profileStyle.personalDetails_Bottom}>
        <Flex
          onClick={() => handleEdit(data._id)}
          flexDirection={"row-reverse"}
          mr={"8"}
          display={userData.userRole === "BENEFICIARY" ? "none" : "flex"}
        cursor={"pointer"}
        >
          <HiOutlinePencilSquare color={"#008470"} fontSize={"23px"} />
        </Flex>
        <Box className={profileStyle.profileDetails_Bot_child}>
          <Box className={profileStyle.profileDetails_Bot_left}>
            <Box>
              <Text className={profileStyle.subTitle}>Basic Info</Text>
              <Flex align={"center"} gap={"13px"} marginTop={"10px"}>
                <AiOutlineMail
                  fontSize="20px"
                  className={profileStyle.icon_Remove}
                />
                <Text className={profileStyle.relationToBen} fontSize={"18px"}>
                  {data.personalInfo.email || "Update now!"}
                </Text>
              </Flex>
            </Box>
          </Box>

          <Box className={profileStyle.profileDetails_Bot_right}>
            <Box className={profileStyle.contactDetails_Right_det}>
              <Flex align={"center"} gap={"10px"}>
                <BsTelephone
                  fontSize="20px"
                  className={profileStyle.icon_Remove}
                />

                <Text className={profileStyle.contact_Number}>
                  {data?.personalInfo?.mobile?.number || "Update now!"}
                </Text>
              </Flex>
              <Box className={""}>
                <Flex align={"center"} gap={"10px"}>
                  <AiOutlineWhatsApp
                    fontSize="20px"
                    className={profileStyle.icon_Remove}
                  />
                  <Text className={profileStyle.contact_Number}>
                    {data?.personalInfo?.whatsappMobile?.number ||
                      "Update now!"}
                  </Text>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={profileStyle.pendingDetails}>
          <Box>
            <Text className={profileStyle.pendingDetail_Title}>Gender</Text>
            <Text className={profileStyle.penDetails_det}>
              {data.personalInfo.gender || "Update now!"}
            </Text>
          </Box>
          <Box>
            <Text className={profileStyle.pendingDetail_Title}>
              {"Age"} <br></br>
            </Text>
            <Text className={profileStyle.penDetails_det}>
              {data?.personalInfo?.dateOfBirth
                ? calculateAge(data?.personalInfo?.dateOfBirth)
                : "Update now!"}
            </Text>
          </Box>
          {/* in feature need to redesign the data base if needed the employmentStatus field need to use this one */}
          <Box>
            {/* <Text className={profileStyle.pendingDetail_Title}>
              Employment Status
            </Text>
            <Text className={profileStyle.penDetails_det}>
              {employmentStatus || "---"}
            </Text> */}
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileBasicDetail;
