import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import styles from '../Orders.module.css'; 
import { Receipt } from './Receipt';
import { formatTimestamp } from '../Utils/utils';
import { FcOk } from "react-icons/fc";
import { BsXCircleFill } from "react-icons/bs";
import { PocoOrderType } from '../Types/types';

export const Order = ({ pocoOrder }: { pocoOrder: PocoOrderType }) => {
    const transactionDateTime = formatTimestamp(pocoOrder.createdAt).toString();
    return (
            <Box className={styles['order-container']}>
                <Flex flexDir="row" margin={{ base: "10px 5px", md: "29px 24px" }}>
                    <Box width="120px" height="79px" borderRadius="6px" display="flex" justifyContent="center" alignItems="center" >
                        <Image
                            src="/Images/pococare_order_logo.png"
                            alt="Poco care Image"
                            width="100%"
                            height="100%"
                            objectFit="cover"
                        />
                    </Box>
                    <VStack paddingLeft="16px" gap={{ base: "4px", sm:"4px", md: "12px" }} align="start">
                        <Flex>
                            <Text className={styles['order-text']} marginRight="12px">
                                {pocoOrder.orderFor.charAt(0).toUpperCase() + pocoOrder.orderFor.slice(1)}
                            </Text>
                            {   pocoOrder.paymentStatus === "paid" ?
                                (<>
                                    <FcOk />
                                    <Text className={styles['order-text']} marginLeft="8px" color="#29A972">Successful</Text>
                                </>)
                                :
                                (<>
                                    <BsXCircleFill color='red' />
                                    <Text className={styles['order-text']} marginLeft="8px" color="#EA1515">Failed</Text>
                                </>)
                            }
                        
                        </Flex>
                        <Text className={styles['order-transaction']}>Transaction ID : #{pocoOrder.transactionId}</Text>
                        {/* This Text will be visible only on screen size lesser than medium */}
                        <Text className={`${styles['transaction-date-time']} ${styles['t-d-t-sm']}`}>Time : {transactionDateTime}</Text>
                        <Text className={styles['order-total']}>Total Paid : Rs.{" " + parseFloat(pocoOrder.amount).toFixed(2) + "/- "}</Text>
                    </VStack>
                </Flex>
                    {/* This div will be floated to the top-right visible only on screen size larger than or equal to medium */}
                <div className={`${styles['transaction-date-time']} ${styles['t-d-t-lg']}`}>
                    <p>{transactionDateTime}</p>
                </div>
                
                {pocoOrder.paymentStatus === "paid" && <Receipt pocoOrderId={ pocoOrder._id } />}
            </Box>
    )
}
