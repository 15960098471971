import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  useMediaQuery,
  HStack
} from "@chakra-ui/react";
import style from "./Footer.module.css"

import { useLocation, useNavigate } from "react-router-dom";
import ContactUsCard from "../../Pages/Landing/PococaremidSection/ContactCard/ContactCard";
// import { useScroll } from "./scroll/ScrollSection";
interface ListHeaderProps {
  children: any
}



const ListHeader: React.FC<ListHeaderProps> = ({ children }) => {
  return <Text className={style.text_Style}>{children}</Text>;
};

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLessThan768] = useMediaQuery("(min-width: 769px)");
  const [isLessThan350] = useMediaQuery("(min-width: 376px)");
  // const { setScrollToSection } = useScroll();

  // const handleSectionClick = (sectionId:any) => {
  //   console.log('sectionId:', sectionId);
  //   setScrollToSection(sectionId);
  // };
  const handleEmailClick = () => {
    window.location.href = "mailto:info@pococare.com";
  };

  const handlePlayStoreIconCick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.pococare.pocoemurgency", "_blank")
  }
  const handleOpenRefundPolicy = () => {
    window.open("./Pococare_Refund_Policy.pdf", "_blank")
  }
  return (
    <>
      <Box mb={"-4rem"} display={"flex"} justifyContent={"center"}>
        {!isLessThan350 ||
          location.pathname === "/contact"
          // location.pathname === "/careers" ||
          // location.pathname === "/terms&condition" ||
          // location.pathname === "/privacyPolicy"
          ? (
            <></>
          ) : (
            <ContactUsCard />

          )}
      </Box>
      <Box
        w={"100%"}
        backgroundColor={"#5B6C78"}
        padding={isLessThan768 ? "2rem" : "3rem"}
      >
        <Container as={Stack} maxW={"9xl"}>
          {isLessThan768 && <Box className={style.footer_grid}>
            <Box>
              <Image src="Images/footer_logo.svg" />
            </Box>
            <Stack align={"flex-start"}>
              <ListHeader>Contact Info</ListHeader>
              <Flex gap={"1rem"}>
                <Box>
                  <Image src="Images/Vectorlocation.svg" />
                </Box>
                <Box>
                  <Text className={style.text_Child}>Bengaluru, India</Text>
                </Box>
              </Flex>
              <Flex gap={"1rem"}>
                <Box>
                  <Image src="Images/VectorcallPhone.svg" />
                </Box>
                <Box>
                  <Text className={style.text_Child}>+91 80560 66766</Text>
                </Box>
              </Flex>
              <Flex gap={"1rem"} alignItems={"center"}>
                <Box >
                  <Image src="Images/Vectormail.svg" />
                </Box>
                <Box>
                  <Text
                    color="#fff"
                    cursor="pointer"
                    as="a"
                    onClick={handleEmailClick}
                    className={style.text_Child}
                  >
                    info@pococare.com
                  </Text>
                </Box>
              </Flex>
              <Flex gap={"1rem"}>
                <Box cursor={"pointer"}>
                  <Image w={"150px"} h={'auto'} className={style.icon_style} src="/Images/playStoreIcon.png" onClick={handlePlayStoreIconCick} />
                </Box>
              </Flex>
            </Stack>


          </Box>}
          {isLessThan768 && <Box display={"flex"} justifyContent={"center"}>
            <Stack justifyContent={"space-between"}>
              <Box>
                <Text
                  color="#fff"
                  fontFamily={`'Poppins', sans-serif`}
                  fontSize={"1rem"}
                  w={"100%"}
                  fontWeight={"400"}
                >
                  © {new Date().getFullYear()} Copyright POCOCARE. All Rights
                  Reserved
                </Text>
              </Box>
              <HStack justify={"center"} spacing={"1rem"}>
                <Text className={style.text_style} onClick={() => navigate("/terms&condition")}>terms and conditions</Text>
                <Text className={style.text_style} onClick={() => navigate("/privacyPolicy")}>privacy policy</Text>
                <Text className={style.text_style} onClick={() => handleOpenRefundPolicy()}>refund policy</Text>
              </HStack >
              <Stack justify={"center"} direction={"row"} spacing={6}>
                <Box>
                  <Image src="Images/linkd_image.svg" />
                </Box>
                <Box>
                  <Image src="Images/youtube_image.svg" />
                </Box>

              </Stack>
            </Stack>
          </Box>}
          {!isLessThan768 && <Box className={style.footer_grid}>
            <Box className={style.footer_logo}>
              <Image src="Images/footer_logo.svg" />
            </Box>


          </Box>}
          {!isLessThan768 && <Box display={"flex"} justifyContent={"center"}>
            <Stack justifyContent={"space-between"}>
              <Box>
                <Text
                  className={style.Copyright_style}

                >
                  © {new Date().getFullYear()} Copyright POCOCARE. All Rights
                  Reserved
                </Text>
              </Box>
              <HStack justify={"center"} spacing={"1rem"}>
                <Text className={style.text_style} onClick={() => navigate("/terms&condition")} >terms and conditions</Text>
                <Text className={style.text_style} onClick={() => navigate("/privacyPolicy")}>privacy policy</Text>
                <Text className={style.text_style} onClick={() => handleOpenRefundPolicy()}>refund policy</Text>
              </HStack >
              <Stack justify={"center"} direction={"row"} spacing={6}>
                <Box className={style.logo_Width}>
                  <Image src="Images/linkd_image.svg" />
                </Box>
                <Box className={style.logo_Width}>
                  <Image src="Images/youtube_image.svg" />
                </Box>

              </Stack>
            </Stack>


          </Box>}
          {!isLessThan768 && <Stack align={"center"}>
            <ListHeader>Contact Info</ListHeader>
            <Flex gap={"1rem"}>
              <Box className={style.icon_style}>
                <Image src="Images/Vectorlocation.svg" />
              </Box>
              <Box>
                <Text className={style.text_Child}>Bengaluru, India</Text>
              </Box>
            </Flex>
            <Flex gap={"1rem"}>
              <Box >
                <Image className={style.icon_style} src="Images/VectorcallPhone.svg" />
              </Box>
              <Box>
                <Text className={style.text_Child}>+91 80560 66766</Text>
              </Box>
            </Flex>
            <Flex gap={"1rem"}>
              <Box>
                <Image className={style.icon_style} src="Images/Vectormail.svg" />
              </Box>
              <Box>
                <Text
                  color="#fff"
                  cursor="pointer"
                  as="a"
                  onClick={handleEmailClick}
                  className={style.text_Child}
                >
                  info@pococare.com
                </Text>
              </Box>
            </Flex>
            <Flex gap={"1rem"}>
              <Box cursor={"pointer"}>
                <Image width={"150px"} height={'auto'} className={style.icon_style} src="/Images/playStoreIcon.png" onClick={handlePlayStoreIconCick} />
              </Box>
            </Flex>
          </Stack>}
        </Container>

      </Box>
    </>
  );
}
