import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Switch,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import classNames from "classnames";
import BasicInfoStyle from "./BasicInfo.module.css";
import ProfilePictureInput from "../ProfilePictureInput/ProfilePictureInput";
import { ShowAlert } from "../../../../GlobalComponents/ShowAlert/ShowAlert";
import {
    updatePersonalInfo,
    uploadFile,
} from "../../Actions/beneficiaryDetails.action";
import { GrFormNext } from "react-icons/gr";
import { calculateAge } from "../../../Profile/Action/calculateAge";
import { useDispatch, useSelector } from "react-redux";
import { getBen } from "../../../Landing/Otp/Action/getBen";
import { BenDataProps } from "../../../../Helper/BenInterface";

interface BasicInfoProps {
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    changeStep: (step: number) => void;
}

export const BasicInfo: React.FC<BasicInfoProps> = ({
    data,
    setData,
    changeStep,
}) => {

    const userData: any = useSelector((state: any) => state.user);
    const phoneInputClass = classNames(BasicInfoStyle.myPhoneInputClass);
    const subscriberData: BenDataProps = useSelector((state: any) => state.subscriber);
    const dispatch = useDispatch()
    const [basicInfoData, setBasicInfoData] = useState({
        image: data.personalInfo?.image || "",
        relationshipToSubscriber: data.personalInfo?.relationshipToSubscriber || "",
        fullName: data.personalInfo.fullName || "",
        gender: data.personalInfo?.gender || "",
        dateOfBirth: calculateAge(data?.personalInfo?.dateOfBirth) || "",
        email: data.personalInfo?.email || "",
        mobile: {
            countryCode: data.personalInfo?.mobile?.countryCode || 91,
            number: Number(data.personalInfo?.mobile?.number) || "",
        },
        whatsappMobile: {
            countryCode: data.personalInfo?.whatsappMobile?.countryCode || 91,
            number: Number(data.personalInfo?.whatsappMobile?.number) || "",
        },
    });

    const [sameWhatsapp, setSameWhatsapp] = useState(
        basicInfoData.mobile.number === ""
            ? false
            : basicInfoData.mobile.number === basicInfoData.whatsappMobile.number
                ? true
                : false
    );

    const [profilePicture, setProfilePicture] = useState(
        basicInfoData?.image || "/Images/profile-pic.png"
    );

    const handleRelation = (value: string) => {
        setBasicInfoData({ ...basicInfoData, relationshipToSubscriber: value });
    };

    const handleFullName = (value: string) => {
        setBasicInfoData({ ...basicInfoData, fullName: value });
    };

    const handleProfilePicture = async (files: any) => {
        setProfilePicture(
            "https://retchhh.files.wordpress.com/2015/03/loading1.gif"
        );
        try {
            let res = await uploadFile(data._id, files, "profilePicture");
            setProfilePicture(res);
        } catch (error: any) {
            console.log("Error in uploading profile picture", error);
            if (error?.response?.data?.message) {
                if (Array.isArray(error.response.data.message)) {
                    return ShowAlert("warning", error.response.data.message[0]);
                }
            }
            ShowAlert("warning", "Some Technical Error");
        }
    };

    const handleGender = (value: string) => {
        setBasicInfoData({ ...basicInfoData, gender: value });
    };

    const handleDob = (value: string) => {
        console.log(value, "value DOB");
        setBasicInfoData({ ...basicInfoData, dateOfBirth: value });
    };

    const handleEmail = (value: string) => {
        setBasicInfoData({ ...basicInfoData, email: value });
    };

    const handlePhone = (value: string) => {
        setBasicInfoData({
            ...basicInfoData,
            mobile: { countryCode: 91, number: Number(value) },
        });
    };

    const handleSameWhatsapp = () => {
        setSameWhatsapp(!sameWhatsapp);

        if (!sameWhatsapp) {
            setBasicInfoData({
                ...basicInfoData,
                whatsappMobile: basicInfoData.mobile,
            });
        }
    };

    const handleWhatsapp = (value: string) => {
        setBasicInfoData({
            ...basicInfoData,
            whatsappMobile: { countryCode: 91, number: Number(value) },
        });
    };

    const updateBasicInfo = async () => {
        let {
            relationshipToSubscriber,
            fullName,
            gender,
            dateOfBirth,
            whatsappMobile,
        } = basicInfoData;

        if (!relationshipToSubscriber) {
            return ShowAlert("warning", "Please Provide Relation To Subscriber");
        }
        if (!fullName || fullName.trim() === "") {
            return ShowAlert("warning", "Please Provide FullName");
        }
        if (!gender) {
            return ShowAlert("warning", "Please Provide Gender");
        }
        if (!dateOfBirth) {
            return ShowAlert("warning", "Please Provide Age");
        }

        if (+dateOfBirth > 150) {
            return ShowAlert("warning", "Please Provide Valid Age");
        }
        if (String(whatsappMobile.number).length !== 10) {
            return ShowAlert("warning", "Please Provide Valid Whatsapp Number");
        }
        if (String(basicInfoData.mobile.number).length !== 10) {
            return ShowAlert("warning", "Please Provide Valid Mobile Number");
        }
        if (!basicInfoData.email) {
            return ShowAlert("warning", "Please Provide Email");
        }
        if (
            !basicInfoData.email.match(
                /^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,5}){1,2}$/
            )
        ) {
            return ShowAlert("warning", "Enter valid email id");
        }

        // const currentDate = getCurrentDate();
        // if (currentDate < basicInfoData.dateOfBirth) {
        //   return ShowAlert("warning", "Please Provide Valid Date Of Birth");
        // }

        let updatePayload = {
            image: profilePicture === "/Images/profile-pic.png" ? "" : profilePicture,
            relationshipToSubscriber: basicInfoData.relationshipToSubscriber,
            fullName: basicInfoData.fullName,
            gender: basicInfoData.gender,
            dateOfBirth: basicInfoData.dateOfBirth.toString(),
            email: basicInfoData.email,
            mobile: {
                countryCode: 91,
                number: Number(basicInfoData.mobile.number),
            },
            whatsappMobile: {
                countryCode: 91,
                number: Number(basicInfoData.whatsappMobile.number),
            },
        };
        console.log(updatePayload, "uploadPayload");
        if (sameWhatsapp) {
            setData({
                ...data,
                personalInfo: {
                    ...basicInfoData,
                    whatsappMobile: {
                        countryCode: basicInfoData.mobile.countryCode,
                        number: basicInfoData.mobile.number,
                    },
                },
            });
            updatePayload.whatsappMobile.countryCode = Number(
                basicInfoData.mobile.countryCode
            );
            updatePayload.whatsappMobile.number = Number(basicInfoData.mobile.number);
        } else {
            setData({ ...data, personalInfo: basicInfoData });
        }
        try {
            
            if (profilePicture.includes("get-document/")) {
                updatePayload.image = profilePicture.split("get-document")[1];
            }
            await updatePersonalInfo(data._id, updatePayload);
            if (data._id === subscriberData._id) {
                await getBen(subscriberData._id, dispatch)
            }
            ShowAlert("success", "Basic Info Updated Successfully");
            changeStep(+1);
        } catch (error: any) {
            if (error?.response?.data?.message) {
                if (Array.isArray(error.response.data.message)) {
                    return ShowAlert("warning", error.response.data.message[0]);
                } else {
                    return ShowAlert("warning", error.response.data.message);
                }
            }
            ShowAlert("warning", "Some Technical Error");
            console.log("Error in updating basic info", error);
        }
    };

    return (
        <>
            <div className={BasicInfoStyle.topInput}>
                {/* @ts-ignore */}
                <div className={BasicInfoStyle.topInputField} width={"60%"}>
                    {basicInfoData.relationshipToSubscriber === "Self" ? (
                        <Select
                            value={basicInfoData.relationshipToSubscriber}
                            onChange={(e) => {
                                handleRelation(e.target.value);
                            }}
                            marginBottom={"8px"}
                            bottom={"10px"}
                            size={"md"}
                            width={"100%"}
                            placeholder="Relationship to subscriber"
                            disabled={
                                basicInfoData?.relationshipToSubscriber === "Self" || userData?.addBeneficiaryAllowed?.toString() === "false"
                                    ? true
                                    : false
                            }
                        >
                            <option value="Self">Self</option>
                        </Select>
                    ) : (
                        <Select
                            value={basicInfoData.relationshipToSubscriber}
                            onChange={(e) => {
                                handleRelation(e.target.value);
                            }}
                            marginBottom={"8px"}
                            bottom={"10px"}
                            size={"md"}
                            width={"100%"}
                            placeholder="Relationship to subscriber"
                            disabled={
                                basicInfoData?.relationshipToSubscriber === "Self" || userData?.addBeneficiaryAllowed?.toString() === "false"
                                    ? true
                                    : false
                            }
                        >
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                            <option value="Spouse">Spouse</option>
                            <option value="Grand Mother">Grand Mother</option>
                            <option value="Grand Father">Grand Father</option>
                            <option value="Father-In-Law">Father-In-Law</option>
                            <option value="Mother-In-Law">Mother-In-Law</option>
                            <option value="Others">Others</option>
                        </Select>
                    )}
                    <Input
                        value={basicInfoData.fullName}
                        onChange={(e) => {
                            handleFullName(e.target.value);
                        }}
                        placeholder="Full Name"
                        size="md"
                        type="text"
                        disabled={
                            userData?.addBeneficiaryAllowed?.toString() === "false" ? true : false
                        }
                    />
                </div>
                <div className={BasicInfoStyle.profilePicContainer}>
                    <ProfilePictureInput
                        //@ts-ignore
                        className={BasicInfoStyle.profilePic}
                        url={profilePicture}
                        handleFiles={handleProfilePicture}
                    />
                </div>
            </div>
            {/* @ts-ignore */}
            <div width={"100%"} className={BasicInfoStyle.otherInput}>
                <Select
                    disabled={userData.userRole === "BENEFICIARY"}
                    value={basicInfoData.gender}
                    onChange={(e) => {
                        handleGender(e.target.value);
                    }}
                    bottom={"15px"}
                    size={"md"}
                    placeholder="Gender"
                >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </Select>
                {/* <Input
                    value={basicInfoData.dateOfBirth}
                    onChange={(e) => {
                        handleDob(e.target.value);
                    }}
                    marginBottom={"15px"}
                    placeholder="Age"
                    type="number"
                    min="1900" max="3000"
                    size="md"
                    onFocus={(e) => (e.target.type = "date")}
                    max={getCurrentDate()}
                /> */}

                <NumberInput
                    // defaultValue={basicInfoData.dateOfBirth || 21}
                    isDisabled={userData.userRole === "BENEFICIARY"}
                    value={basicInfoData.dateOfBirth}
                    mb={4}
                    step={1}
                    onChange={(value) => {
                        handleDob(value.replace(/[^0-9]/g, ""));
                    }}
                    placeholder="Age"
                    min={0}
                // max={120}
                >
                    <NumberInputField placeholder="Age" />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>

                <Input
                    disabled={userData.userRole === "BENEFICIARY"}
                    value={basicInfoData.email}
                    onChange={(e) => {
                        handleEmail(e.target.value);
                    }}
                    marginBottom={"15px"}
                    placeholder="Email"
                />
            </div>

            <PhoneInput
                disabled={userData.userRole === "BENEFICIARY"}
                country="in"
                onlyCountries={["in"]}
                autoFormat={true}
                disableCountryCode={true}
                inputClass={phoneInputClass}
                value={
                    basicInfoData.mobile.number ? `${basicInfoData.mobile.number}` : ""
                }
                placeholder="Phone Number"
                onChange={(value) => {
                    const phoneNumber = value.replace(/\D/g, "");
                    handlePhone(`${phoneNumber}`);
                }}
            />
            <FormControl
                isDisabled={userData.userRole === "BENEFICIARY"}
                marginBottom={"15px"}
                marginTop={"15px"}
                gap="20px"
                display="flex"
                alignItems="center"
            >
                <Switch
                    onChange={() => handleSameWhatsapp()}
                    isChecked={sameWhatsapp ? true : false}
                    id="same-whatsappNumber"
                />
                <FormLabel color={"#1C2B2F"} htmlFor="same-whatsappNumber" mb="0">
                    WhatsApp number is same as mobile number
                </FormLabel>
            </FormControl>
            {sameWhatsapp ? (
                ""
            ) : (
                <PhoneInput
                    //@ts-ignore
                        disabled={userData.userRole === "BENEFICIARY"}
                    country="in"
                    onlyCountries={["in"]}
                    autoFormat={true}
                    disableCountryCode={true}
                    inputClass={phoneInputClass}
                    value={
                        basicInfoData.whatsappMobile.number
                            ? `${basicInfoData.whatsappMobile.number}`
                            : ""
                    }
                    //@ts-ignore
                    name="Whatsapp Number"
                    placeholder="Whatsapp Number"
                    onChange={(value) => {
                        const phoneNumber = value.replace(/\D/g, "");
                        handleWhatsapp(`${phoneNumber}`);
                    }}
                />
            )}

            <HStack marginTop={"30px"} justifyContent="space-between">
                <Button
                    isDisabled={userData.userRole === "BENEFICIARY"}
                    marginTop={"20px"}
                    onClick={() => updateBasicInfo()}
                    colorScheme="green"
                    variant={"solid"}
                >
                    Save and continue
                </Button>
                {data.personalInfo?.dateOfBirth &&
                    data.personalInfo?.email &&
                    data.personalInfo?.whatsappMobile?.number &&
                    data.personalInfo?.mobile?.number ? (
                    <GrFormNext
                        onClick={() => changeStep(+1)}
                        className={BasicInfoStyle.greenIcon}
                    />
                ) : null}
            </HStack>
        </>
    );
};
