import { Box, Image, Text, useMediaQuery } from "@chakra-ui/react";
const TxtComponent = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isTab] = useMediaQuery("(max-width: 768px)");

  return (
    <Box>
      {/* Left side with logo and description */}
      <Box p={isMobile ? "0px":  "8px"} marginLeft="0px" textAlign={"left"}>
        {!isMobile && !isTab && (
          <Image
            width={"60%"}
            src="/Images/poco-care-text-logo.png"
            alt="PocoCare Logo"
            mb="40px"
          />  
        )}
        
        <Text
          color="#333"
          fontSize={isMobile ? "9px":"16px"}
          fontFamily="Poppins"
          fontWeight="500"
          lineHeight={isMobile ? "15px":"26px"}
          letterSpacing="0.06em"
          fontStyle="normal"
          mb={isMobile? "0px": "2px"}
        >
          Medical Emergency
        </Text>
        <Text
           color="#333"
           fontSize={isMobile ? "9px":"16px"}
           fontFamily="Poppins"
           fontWeight="500"
           lineHeight={isMobile ? "15px":"26px"}
           letterSpacing="0.06em"
           fontStyle="normal"
           mb={isMobile? "0px": "2px"}
        >
          "Incident to Hospital Admission"
          <br />
          Service Anytime
        </Text>
        <Text
          color="#333"
          fontSize={isMobile ? "7px":"12px"}
          fontFamily="Poppins"
          fontWeight="400"
          lineHeight={isMobile ? "15px":"26px"}
          letterSpacing="0.06em"
          fontStyle="normal"
        >
          “Peace of Mind for you and your family”
        </Text>
      </Box>
    </Box>
  );
};

export default TxtComponent;
