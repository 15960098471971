import { Box, Button, Image, ListItem, Text, UnorderedList, useMediaQuery } from "@chakra-ui/react"
import Navbar from "../../../GlobalComponents/Navbar/Navbar"
import { useNavigate } from "react-router-dom";

export const B2CSubscription: React.FC = () => {
    const [isMobile] = useMediaQuery("(max-width: 480px)");
    const navigate = useNavigate();

    const handleRegister = () => {
        return navigate("/registration");
    }

    return (
        <>  
            <Navbar/>
            <Box 
                margin={"auto"}
                marginTop={isMobile ? "4rem":"5rem"}
                bgGradient="linear-gradient(180deg, #FFFFFF 0%, #D2F0F2 100%)"
                color={"#333"}
                position={"relative"}
                minH={"100vh"}
                width={"full"}
            >
                <Box 
                    height={isMobile? "191px":"303px"}
                    background={"rgba(255, 225, 241, 0.79)"}
                    position={"relative"}
                >
                    <Box
                       paddingTop={isMobile ? "15px":"51px"}
                       paddingLeft={isMobile ? "16px":"84px"}
                    >
                        <Text
                           fontFamily={"Poppins"}
                           fontSize={isMobile ? "12px":"24px"}
                           lineHeight={isMobile ? "24px":"38px"}
                           fontWeight={"500"}
                           letterSpacing={"0.06em"} 
                           color="rgba(51, 51, 51, 1)"
                           paddingRight={isMobile ? "30px" : "auto"}
                        >
                            <span style={{fontFamily:"Poppins", color:"rgba(247, 3, 136, 1)"}}>Peace</span> Of Mind from <span style={{fontFamily:"Poppins", color:"rgba(0, 167, 181, 1)"}}>Medical Emergencies</span>, for you and your family,
                            {isMobile ? null : <br/>}
                            with <span style={{fontFamily:"Poppins", color:"rgba(240, 56, 156, 1)"}} >fastest medical assistance</span> at your fingertips.
                        </Text>
                    </Box>
                    <Box 
                        width={isMobile ? "152px" : "300px"}
                        height={isMobile ? "115px" : "227px"}
                        position={"absolute"}
                        top="76"
                        right= {isMobile ? "0":"100"} 
                    >
                        <Image src="/Images/smiling-family-portrait.png" alt="Smiling family portrait" width={"100%"} height={"100%"} objectFit="cover" />
                    </Box>
                </Box>
                <Box
                    width={ isMobile ? "92%":"75%"}
                    margin={"auto"}
                    padding={isMobile ? "9px":"24px"}
                    paddingTop={isMobile ? "16px" : "24px"}
                    position={"relative"}
                    top={isMobile ? "-40px" : "-60px"}
                    border={"1px solid rgba(240, 56, 156, 1)"}
                    borderRadius={"4px"}
                    background={"rgba(255, 255, 255, 0.4)"}
                >
                    <Box 
                        display={"flex"}
                        flexDirection={isMobile ? "column": "row"}
                        gap={"3%"}
                        justifyContent={"center"} 
                    >
                        <Box 
                            flex={"1"}
                            padding={"4px"}
                            background="rgba(255, 255, 255, 1)"
                            border={"1px solid rgba(221, 221, 221, 1)"}
                            boxSizing="border-box"
                            marginBottom={isMobile ? "10px" : "auto"}
                        >   
                            <Box 
                                display="flex" 
                                justifyContent="center" 
                                alignItems="center"
                                background="rgba(240, 56, 156, 0.4)"
                            >
                                <Text
                                    fontFamily="Poppins"
                                    fontSize= {isMobile ? "16px":"22px"}
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="0.06em"
                                    textAlign="center"
                                    paddingTop={isMobile ? "8px":"16px"}
                                    paddingBottom={isMobile ? "8px":"19px"}
                                >
                                    Readiness
                                </Text>
                            </Box>

                            <Box
                                minHeight={isMobile ? "auto":"344px"}
                                marginTop={isMobile ? "11px":"16px"} 
                                marginBottom={isMobile ? "14px":"16px"}
                                padding={"4px"}
                                fontSize={isMobile ? "10px":"12px"}
                                letterSpacing={"0.06em"}
                                fontWeight={"400"}
                                boxSizing="border-box"
                                lineHeight={isMobile ? "18px":"26px"}
                            >
                                <UnorderedList
                                        spacing={isMobile ? "1":"2"}
                                >
                                    <ListItem fontFamily="Poppins">Personalized Emergency Response Plan: Customized plan based on medical history, location, and family needs.</ListItem>
                                    <ListItem fontFamily="Poppins">24/7 Access to Medical Experts: Consult with healthcare professionals anytime for guidance or reassurance.</ListItem>
                                    <ListItem fontFamily="Poppins">Family Member Inclusion: Option to add family members to the plan, ensuring everyone is prepared.</ListItem>
                                    <ListItem fontFamily="Poppins">Emergency Contact Management: Update and manage emergency contacts within the system. </ListItem>
                                </UnorderedList>
                            </Box> 
                        </Box>
                        <Box 
                            flex={"1"}
                            padding={"4px"}
                            background="rgba(255, 255, 255, 1)"
                            border={"1px solid rgba(221, 221, 221, 1)"}
                            boxSizing="border-box"
                            marginBottom={isMobile ? "10px" : "auto"}
                        >   
                            <Box
                                display="flex" 
                                justifyContent="center" 
                                alignItems="center"
                                background="rgba(56, 91, 240, 0.46)"

                            >
                                <Text
                                    fontFamily="Poppins"
                                    fontSize= {isMobile ? "16px":"22px"}
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="0.06em"
                                    textAlign="center"
                                    paddingTop={isMobile ? "8px":"16px"}
                                    paddingBottom={isMobile ? "8px":"19px"}
                                >
                                    Personalization
                                </Text>
                            </Box>
                            <Box
                                minHeight={isMobile ? "auto":"344px"}
                                marginTop={isMobile ? "11px":"16px"} 
                                marginBottom={isMobile ? "14px":"16px"}
                                padding={"4px"}
                                fontSize={isMobile ? "10px":"12px"}
                                letterSpacing={"0.06em"}
                                fontWeight={"400"}
                                boxSizing="border-box"
                                lineHeight={isMobile ? "18px":"26px"}
                            >
                                <UnorderedList
                                    spacing={isMobile ? "1":"2"}
                                >
                                    <ListItem fontFamily="Poppins">Ongoing Medical Profile Updates: Keep medical information current for the most effective response. </ListItem>
                                    <ListItem fontFamily="Poppins">Location-Based Services: System adapts to the member's current location for the fastest possible assistance.</ListItem>
                                    <ListItem fontFamily="Poppins">Preference-Driven Communication: Members choose their preferred method of contact in an emergency. </ListItem>
                                </UnorderedList>
                            </Box> 
                        </Box>
                        <Box
                            flex={"1"}
                            padding={"4px"}
                            background="rgba(255, 255, 255, 1)"
                            border={"1px solid rgba(221, 221, 221, 1)"}
                            boxSizing="border-box"
                        >   
                            <Box
                                display="flex" 
                                justifyContent="center" 
                                alignItems="center"
                                background="rgba(41, 169, 114, 0.54)"
                            >
                                <Text
                                    fontFamily="Poppins"
                                    fontSize= {isMobile ? "16px":"22px"}
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="0.06em"
                                    textAlign="center"
                                    paddingTop={isMobile ? "8px":"16px"}
                                    paddingBottom={isMobile ? "8px":"19px"}
                                >
                                    Response
                                </Text>
                            </Box>
                            <Box 
                                minHeight={isMobile ? "auto":"344px"}
                                marginTop={isMobile ? "11px":"16px"} 
                                marginBottom={isMobile ? "14px":"16px"}
                                padding={"4px"}
                                fontSize={isMobile ? "10px":"12px"}
                                letterSpacing={"0.06em"}
                                fontWeight={"400"}
                                boxSizing="border-box"
                                lineHeight={isMobile ? "18px":"26px"}
                            >
                                <UnorderedList
                                    spacing={isMobile ? "1":"2"}
                                >
                                    <ListItem fontFamily="Poppins">Immediate Emergency Assistance: Rapid response time and direct connection to emergency services. </ListItem>
                                    <ListItem fontFamily="Poppins">Real-Time Tracking & Coordination: System tracks the emergency and coordinates with relevant parties (hospitals, family, etc.). </ListItem>
                                    <ListItem fontFamily="Poppins">Hospital Admission Facilitation: Assistance provided in securing hospital admission and ensuring a smooth transition.</ListItem>
                                </UnorderedList>
                            </Box>
                        </Box>
                    </Box>
                    {
                        isMobile 
                        ?
                        <Box
                            border={" 0.65px solid rgba(0, 0, 0, 0.1)"}
                            marginTop={"25.89px"}
                            marginBottom={"12.11px"}
                        >
                        </Box>
                        :
                        null
                    }
                    <Box 
                        margin={"auto"}
                        marginTop={isMobile ? "auto" : "40px"}
                        marginBottom={ isMobile ? "38px" : "auto" }
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}  
                    >
                        <Button 
                            onClick={handleRegister} 
                            width={isMobile ? "100%" : "48%"}
                            backgroundColor="#01A7B5"
                            color="#FFF"
                            fontFamily="Poppins"
                            fontSize={isMobile ? "12px" : "18px"}
                            fontWeight="500"
                            lineHeight={isMobile ? "23.28px" : "36px"}
                            letterSpacing="0.06em"
                            borderRadius={isMobile ? "5.17px" : "8px"}
                            _hover={{ bg: "teal.600" }}
                            zIndex={5}
                        >
                            Register
                        </Button>
                    </Box>
                </Box>
                <Box position={"relative"} top={isMobile ? "-20" :"-130"} left={0}>
                        <Image
                            width={isMobile ? "50%" :"552.21px"}
                            src="Images/group.png"
                            objectFit="cover"
                            pointerEvents="none"
                            zIndex={0}
                        />
                </Box>
            </Box>
        </>
    )
}